<script>
  import darkThemeLogo from "/@/assets/image/logo_dark_theme.svg"
  import lightThemeLogo from "/@/assets/image/logo_light_theme.svg"
  import { isDark } from "/@/theme"
  import userAnalytics, {
    headerNavTastyworksLogoImage,
  } from "/@/util/user-analytics"

  export let height = null
  let cssClass = ""
  export { cssClass as class }

  $: getSrc = $isDark ? darkThemeLogo : lightThemeLogo
</script>

<img
  use:userAnalytics={headerNavTastyworksLogoImage}
  class="tastyworks-logo my-small {cssClass}"
  src={getSrc}
  alt="tastytrade"
  style:height
/>
