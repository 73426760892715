import { toast } from "@zerodevx/svelte-toast"
import { registerSW } from "virtual:pwa-register"
import { AlertSeverity } from "/@/control/alert"
import ReloadAlertMessage from "./ReloadAlertMessage.svelte"
import { webPushEnabled } from "/@/trading/web-push"

export function configureServiceWorker() {
  const updateServiceWorker = registerSW({
    onNeedRefresh() {
      console.log("SW needs refresh")
      toast.push({
        classes: [AlertSeverity.INFO],
        component: {
          props: {
            updateServiceWorker,
          },
          sendIdTo: "toastId",
          src: ReloadAlertMessage,
        },
        dismissable: false,
        initial: 1,
        next: 1,
        theme: {
          "--toastPadding": "0",
          "--toastBorder": "none",
          "--toastBackground": "transparent",
        },
      })
    },
    onOfflineReady() {
      console.log("service worker offline ready")
    },
    onRegisteredSW(_swScriptUrl, registration) {
      if (!registration) {
        return
      }

      console.log("service worker registered")

      registration.pushManager.getSubscription().then((subscription) => {
        webPushEnabled.set(!!subscription)
      })
    },
    onRegisterError(error) {
      console.log("service worker registration error", error)
    },
  })
}
