<script lang="ts">
  import { Alert, AlertSeverity } from "/@/control/alert"
  import { toast } from "@zerodevx/svelte-toast"

  const severity = AlertSeverity.INFO

  export let toastId = null
  export let updateServiceWorker

  function reload() {
    console.log("SW reloading")
    updateServiceWorker(true)
    toast.pop(toastId)
  }

  function close() {
    toast.pop(toastId)
  }
</script>

<Alert {severity}>
  <p>Updated content available. Click Reload to update</p>
  <br />
  <button class="button" on:click={reload}>Reload</button>
  <button class="button" on:click={close}>Close</button>
</Alert>
